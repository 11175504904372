<template>
	<div>
		<div ref="dygraphContainer" style="width: 100%;"></div>
		<div class="d-flex justify-content-center align-items-center ml-15" style="min-height: 40px;">
			<div ref="dygraphLegend"></div>
		</div>
	</div>
</template>

<script>
import Dygraph from 'dygraphs';

export default {
	name: 'dygraphs',
	props: {
		data: {
			type: Array,
			required: true,
		},
		options: {
			type: Object,
			required: false,
		},
		annotations: {
			type: Array,
			required: false,
		},
		formatLegend: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data: function() {
		return {
			dygraph: null,
		};
	},
	methods: {
		drawGraph() {
			if (this.dygraph) {
				this.dygraph.destroy();
			}
			this.dygraph = new Dygraph(this.$refs.dygraphContainer, this.data, this.opts);
			this.dygraph.ready(() => {
				this.annotations && this.dygraph.setAnnotations(this.annotations);
			});
		},
	},
	computed: {
		opts() {
			//changing options trigger the watcher, so make a copy to assign the $ref
			let localOpts = {
				labelsDiv: this.$refs.dygraphLegend,
			};
			if (this.formatLegend) {
				localOpts.legendFormatter = function(data) {
					let legends = [];
					data.series.forEach((element, index) => {
						if (element.yHTML) {
							let span = index === 0 ? data.xHTML + ': ' : '';
							span += `<span style="font-weight:bold; color:${element.color};"> ${element.labelHTML}:</span><span> ${element.yHTML || '-'}</span>`;
							legends.push(span);
						}
					});
					return legends;
				};
			}
			return { ...this.options, ...localOpts };
		},
	},
	mounted() {
		this.drawGraph();
	},
	beforeDestroy() {
		this.dygraph.destroy();
	},
	watch: {
		data: {
			handler: function(newData, oldData) {
				this.dygraph.updateOptions({ file: newData });
				this.annotations && this.dygraph.setAnnotations(this.annotations);
			},
		},
		options: {
			handler: function(newOptions) {
				this.dygraph.updateOptions(newOptions);
			},
			deep: true,
		},
	},
};
</script>
